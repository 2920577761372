import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import { fimg } from '../src/utils/common'
import print from '@/utils/print'
Vue.config.productionTip = false
Vue.prototype.$fimg = fimg
Vue.use(ElementUI)
Vue.use(print)
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app")