// 引入axios
import axios from "axios";
// 1.准备baseurl
import { baseUrl } from "./config";
// 2.创建新的axios实例  service
const service = axios.create({
    baseURL: baseUrl,
    // 超时时间
    timeout: 30000,
    headers: { "X-Custom-Header": "foobar" },
});
//   3.添加请求拦截器
// 请求拦截器
service.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么.
        if (localStorage.getItem("token")) {
            config.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
        }
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
// 4.添加响应拦截器
// 添加响应拦截器
service.interceptors.response.use(
    (response) => {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        // 手动清除 Toast
        if (response.data.code == 1011008 || response.data.code == 1011004 || response.data.code == 1011006) {
            localStorage.clear();
            // this.$message.error('登录过期,请重新登录')
            setTimeout(() => {
                window.location.href = '/'
            }, 1000);
        } else if (response.data.code == 500) {
            // window.alert(response.data.message)
        }

        return response.data;
    },
    (error) => {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);
// 5.向外抛出实例对象
export default service