import Vue from "vue"
import VueRouter from "vue-router"
import Login from "../views/Login.vue"
import MyInfo from "../views/MyInfo.vue"
import Index from "../views/Index.vue"
import IndexDefault from "../views/IndexDefault.vue"
import Exhibitor from "../views/Exhibitor.vue"
import { component } from "vue/types/umd"

Vue.use(VueRouter)

const routes = [{
        path: "/",
        component: Login,
    },
    {
        path: "/index",
        component: Index,
        children: [{
                path: "",
                component: IndexDefault,
            },
            {
                path: "exhibitor",
                component: Exhibitor,
                children: [{
                        path: "",
                        component: () =>
                            import ("@/views/EnterpriseInfo.vue"),
                    },
                    {
                        path: "exhibitiontype",
                        component: () =>
                            import ("@/views/ExhibitionType.vue"),
                    },
                    {
                        path: "exhibitsinfo",
                        component: () =>
                            import ("@/views/ExhibitsInfo.vue"),
                    },
                    {
                        path: "facility",
                        component: () =>
                            import ("@/views/Facility.vue"),
                    },
                    {
                        path: "lease",
                        component: () =>
                            import ("@/views/Lease.vue"),
                    },
                    {
                        path: "accountinfo",
                        component: () =>
                            import ("@/views/AccountInfo.vue"),
                    },
                    {
                        path: "successdeclare",
                        component: () =>
                            import ("@/views/SuccessDeclare.vue"),
                    },
                ],
            },
            {
                path: "setupnewspaper",
                component: () =>
                    import ("@/views/SetUpNewspaper.vue"),
                children: [{
                        path: "",
                        component: () =>
                            import ("@/views/AddBooth.vue"),
                    },
                    {
                        path: "exhibit",
                        component: () =>
                            import ("@/views/Exhibit.vue"),
                    },
                    {
                        path: "homeexpenses",
                        component: () =>
                            import ("@/views/HomeExpenses.vue"),
                    },
                    {
                        path: "setuplease",
                        component: () =>
                            import ("@/views/SetUpLease.vue"),
                    },
                    {
                        path: "setaccountinfo",
                        component: () =>
                            import ("@/views/SetUpAccountinfo.vue"),
                    },
                    {
                        path: "documentreview",
                        component: () =>
                            import ("@/views/DocumentReview.vue"),
                    },
                    {
                        path: "setupsuccessdeclare",
                        component: () =>
                            import ("@/views/SetUpSuccessDeclare.vue"),
                    },
                ],
            },
            {
                path: "myinfo",
                component: MyInfo,
                children: [
                    // {
                    //     path: "",
                    //     component: () =>
                    //         import ("../views/Info.vue"),
                    // },
                    {
                        path: "",
                        component: () =>
                            import ("../views/OrderForm.vue"),
                        children: [{
                                path: "",
                                component: () =>
                                    import ("../views/OrderForm1.vue"),
                            },
                            {
                                path: "orderform2",
                                component: () =>
                                    import ("../views/OrderForm2.vue"),
                            },
                            {
                                path: "orderform3",
                                component: () =>
                                    import ("../views/OrderForm3.vue"),
                            },
                            {
                                path: "orderform4",
                                component: () =>
                                    import ("../views/OrderForm4.vue"),
                            },
                            {
                                path: "orderform5",
                                component: () =>
                                    import ("../views/OrderForm5.vue"),
                            },
                        ],
                    },
                    {
                        path: "changepassword",
                        component: () =>
                            import ("../views/ChangePassWord.vue"),
                    },
                ],
            },
            {
                path: "hotelreservation",
                component: () =>
                    import ("../views/HotelReservation.vue"),
            },
            {
                path: "downloadcenter",
                component: () =>
                    import ("../views/DownloadCenter.vue"),
            },
        ],
    },
    {
        path: "/paymentorder",
        component: () =>
            import ("@/views/PaymentOrder")
    }
]

const router = new VueRouter({
    mode: "hash",
    routes,
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return VueRouterPush.call(this, location).catch((err) => err)
}

export default router